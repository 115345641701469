import React, { useEffect, useState } from "react"
import axios from "axios"

import { useAuthContext } from "../../auth/context"

import PageLayout from "../app/page-layout"
import Table from "../home/table"

import Documents from "../../api/documents"

const Home = () => {
  const { token } = useAuthContext()
  const [ table, setTable ] = useState([])

  useEffect(() => {
    axios.get("/settings.json").then(resp => {
      Documents(token, resp.data.apiEndpoint).then(list => {
        console.log(list)
        setTable(list)
      })
    }).catch(console.log) 
  }, [token])

  return (
    <PageLayout title="Data" description="Knowledge Management Service - Document Identifer Registry">
      <Table data={table}></Table>
    </PageLayout>
  )
}

export default Home