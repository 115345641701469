import React from "react"
import { useHistory } from "react-router-dom"

import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Heading from "@amzn/meridian/heading"
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb"
import Loader from "@amzn/meridian/loader"

import { colorGray200 } from "@amzn/meridian-tokens/base/color"

const headingStyle = {
  borderBottom: `1px solid ${colorGray200}`,
}

const PageLayout = ({
  children,
  title,
  description,
  loading,
  breadcrumbs = [],
  spacing = "large",
}) => {
  const history = useHistory()
  return (
    <div>
      <div style={headingStyle}>
        <Column
          alignmentHorizontal="center"
          backgroundColor="alternateSecondary"
          spacingInset="medium large"
        >
          <Row alignmentHorizontal="justify" width={1024} maxWidth="100%">
            {title ? (
              <Heading level={1} type="h400">
                {title}
              </Heading>
            ) : null}
          </Row>
          <Row alignmentHorizontal="justify" width={1024} maxWidth="100%">
            {description ?  (<Text>
              {description}
            </Text>) : null }
          </Row>
        </Column>
      </div>
      <Column alignmentHorizontal="center" spacingInset="large large xlarge">
        <Column width={1024} maxWidth="100%" spacing={spacing}>
          {breadcrumbs.length > 0 ? (
            <BreadcrumbGroup>
              {breadcrumbs.map(({ title, path }) => (
                <Breadcrumb key={path} href={path} onClick={history.push}>
                  {title}
                </Breadcrumb>
              ))}
              <Breadcrumb>{title}</Breadcrumb>
            </BreadcrumbGroup>
          ) : null}
          {loading ? (
            <Column alignmentHorizontal="center" spacingInset="xlarge none">
              <Loader type="circular" />
            </Column>
          ) : (
            children
          )}
        </Column>
      </Column>
    </div>
  )
}

export default PageLayout