import React from "react"

import Masthead, {MastheadTitle} from "@amzn/meridian/masthead"
import Text from "@amzn/meridian/text"

const AppMasthead = () => {
  return (
    <Masthead>
      <MastheadTitle>
        <Text type="h400">KMSDID</Text>
      </MastheadTitle>
    </Masthead>
  )
}

export default AppMasthead 