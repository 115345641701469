import React from "react"

import Table, {
  TableRow,
  TableCell,
} from "@amzn/meridian/table"

const HomeTable = ({ data }) => {
  console.log(data)

  // const tableItems = []

  const tableItems = data.map((r) => 
    <TableRow>
      <TableCell>{r.did}</TableCell>
      <TableCell>{r.title}</TableCell>
      <TableCell><a href={r.location}>{r.filename}</a></TableCell>
      <TableCell>{r.owner}</TableCell>
      <TableCell>{r.status}</TableCell>
    </TableRow>
  )

  return (
    <Table headerRows={1}>
      
      <TableRow>
        <TableCell>DID</TableCell>
        <TableCell>Title</TableCell>
        <TableCell>Location</TableCell>
        <TableCell>Owner</TableCell>
        <TableCell>Status</TableCell>
      </TableRow>

      {tableItems}
    </Table>
  )
}

export default HomeTable