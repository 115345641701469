import React, { createContext, useContext, useState, useEffect } from "react";

import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";

import axios from "axios"

import { shouldAuth, tokens } from "./auth"
// import constants from "./constants"

const AuthContext = createContext()
const useAuthContext = () => useContext(AuthContext)

const AuthContextProvider = ({children}) => {
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState({})

  useEffect(() => {
    const initAuth = async () => {
      if (shouldAuth()) {
        localStorage.setItem("url", window.location)
        
        axios.get("/settings.json").then(resp => {
          const { cognitoUrl } = resp.data
 
          window.location = cognitoUrl
        }).catch(error => {
          console.log(error)
        })
      } else {
        const { idToken } = tokens()

        setToken(idToken)
        setLoading(false)
  
        const location = localStorage.getItem("url")
        if (location) {
          localStorage.removeItem("url")
          window.location = location
        }
      }
    }

    initAuth()
  })

  if (loading) {
    return (
      <Column>
        <Column
          maxWidth="550px"
          spacingInset="large none xlarge"
        >
          <Text type="h400">Loading</Text>
          <Text>
            Just grabbing an access token for you
          </Text>
      </Column>
    </Column>
    )
  }

  return (
    <AuthContext.Provider value={{token: token}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider 

export {
  useAuthContext,
  AuthContext
}