import React from "react"

import PageLayout from "../app/page-layout"

const Login = () => {
  return (
    <PageLayout title="Login">
    </PageLayout>
  )
}

export default Login