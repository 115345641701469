import React from "react"
import Router from "./router/router"

import CurrentScreen from "./router/current-screen"
import Masthead from "./components/app/masthead"

import AppLayout from "@amzn/meridian/app-layout"

function App() {
  return (
    <Router>
      <AppLayout headerComponent={Masthead}>
        <Masthead></Masthead>
        <CurrentScreen />
      </AppLayout>
    </Router>
  );
}

export default App;
