import { isAfter, addSeconds } from "date-fns"

const shouldAuth = () => {
  return (!isAuthenticated() && !isHandshakeReady())
}

const isAuthenticated = () => {
  const accessToken = localStorage.getItem("accessToken")
  const expiry = localStorage.getItem("expiry")

  if (accessToken && isAfter(new Date(expiry), new Date())) {
    return true
  }

  return false
}

const isHandshakeReady = () => {
  const params = new URLSearchParams(window.location.hash.replace("#", ""))

  return params.has("access_token") && params.has("id_token")
}

const tokens = () => {
  const idToken = localStorage.getItem("idToken")
  const accessToken = localStorage.getItem("accessToken")
  const expiry = localStorage.getItem("expiry")

  if (accessToken && isAfter(new Date(expiry), new Date())) {
    return {
      accessToken,
      idToken
    }
  }

  const params = new URLSearchParams(window.location.hash.replace("#", ""))
  
  localStorage.setItem("accessToken", params.get("access_token"))
  localStorage.setItem("idToken", params.get("id_token"))
  localStorage.setItem("expiry", addSeconds(new Date(), params.get("expires_in")))

  return {
    accessToken: params.get("access_token"),
    idToken: params.get("id_token")
  }
}

export { shouldAuth, tokens }