import React from "react"
import { Switch, Route } from "react-router-dom"

import Home from "../components/screens/home"
import Login from "../components/screens/login"

const CurrentScreen = () => {
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  )
}

export default CurrentScreen