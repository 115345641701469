import axios from "axios"

const Documents = (idToken, url) => {
  const headers = {
    headers: {
      "Authorization": idToken
    }
  }

  return new Promise((resolve, reject) => {
    axios.get(url, headers).then(resp => {
      const { rows } = resp.data
      resolve(rows)
    }).catch(err => {
      reject(err)
    })
  })
}

export default Documents